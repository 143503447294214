import qs from 'qs';
import axios from 'axios';
import store from '@/store/index';
import { ElMessage, ElLoading } from 'element-plus';
import { translate } from '@/utils/i18n';
import { login } from '@/api/login';

window.BASE_URL = process.env.VUE_APP_FLAG
const service = axios.create({
  baseURL: window.BASE_URL, // api 的 base_url
  timeout: 15000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  config => {
    const accessToken = JSON.parse(localStorage.getItem('accessToken')) || ''
    config.headers['Lang'] = localStorage.getItem('language') || 'zh-CN'
    if (accessToken) {
      config.headers['Mate-Auth'] = `Bearer ${accessToken.value}`
      // config.headers['Content-Type'] = 'application/json;charset=UTF-8' // axios自动设置，手动设置后会导致上传图片失败
      config.headers['Version'] = 'v1'
      config.headers['Client-Type'] = 'data-center'
    }
    if (config.data && config.headers['Content-Type'] == 'application/x-www-form-urlencoded;charset=UTF-8') {
      config.data = qs.stringify(config.data)
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response拦截器
service.interceptors.response.use(
  response => {
    if (response.status === 200 && response.config.getAllData) { // 返回全部数据
      if (response.data.code && response.data.code != 200) {
        ElMessage({ type: 'warning', showClose: true, message: response.data.msg || translate('网络请求失败，请稍候再试') })
        return Promise.reject(response.data)
      } else {
        return response
      }
    } else if (response.data.code != 200) {
      ElMessage({ type: 'warning', showClose: true, message: response.data.msg || translate('网络请求失败，请稍候再试') })
      return Promise.reject(response.data)
    } else {
      return response.data
    }
  },
  error => {
    if (error.response.status == 401) {
      store.dispatch('logOut');
      localStorage.setItem('LoginStatusHasExpired', '登录状态已过期');
      autoLogin(); // 自动登录
      return Promise.reject(error)
    }
    ElMessage({ type: 'error', showClose: true, message: error.response.data.msg || translate('网络请求失败，请稍候再试') })
    return Promise.reject(error)
  }
)

// 自动登录
let autoLoginFlag = false; // 是否正在登录
function autoLogin(){
  if (!autoLoginFlag) {
    autoLoginFlag = true;
  } else {
    return
  }
  const name = localStorage.getItem('login-name') || '';
  const username = localStorage.getItem('login-username') || '';
  const password = localStorage.getItem('login-password') || '';
  if (name && username && password) {
    const loading = ElLoading.service({
      lock: true,
      text: '自动登录中...',
      background: 'rgba(0, 0, 0, 0.7)',
    })
    login({ name, username, password }).then(response => {
      store.dispatch('setUserInfo', response.data)
      ElMessage.success(translate('自动登录成功'))
      location.reload();
    }).catch(() => {
      loading.close();
      store.dispatch('logOut');
      autoLoginFlag = false;
      location.href = location.origin;
    })
  } else {
    store.dispatch('logOut');
    location.href = location.origin;
  }
}

export default service
